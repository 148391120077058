export default {
  me: {},
  textId: null,
  text: {},
  texts: [],
  tokens: [],
  selectedToken: null,
  selectedTokenHistory: [],
  nodes: {},
  lemmas: {},
  forms: {},
  vocabLists: [],
  personalVocabList: {},
  vocabList: {},
  vocabListType: null,
  selectedVocabList: null,
  showInVocabList: true,
  personalVocabLangList: [],
  personalVocabAdded: false,
  vocabAdded: false,
  latticeNodes: [],
  bookmarks: [],
  supportedLanguages: [],
  partialMatchForms: [],
  partialMatchLemmas: [],
};
